export type NotificationResult = {
  banners: NotificationItem[];
  alerts: NotificationItem[];
};

export type NotificationItem = {
  notificationId?: string;
  iconName?: string;
  header?: string;
  body: string;
  actionText?: string;
  actionUrl?: string;
  severity?: NotificationSeverity;
  priority?: NotificationPriority;
  backgroundColorOverride?: string;
  leftButtonText?: string;
  leftButtonVariant?: string;
  leftButtonAction?: string;
  rightButtonText?: string;
  rightButtonVariant?: string;
  rightButtonAction?: string;

};

export enum NotificationPriority {
  Highest = 'Highest',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Lowest = 'Lowest',
}

export enum NotificationSeverity {
  Error = 'Error',
  Warning = 'Warning',
  Success = 'Success',
  Info = 'Info',
}

export enum NotificationActions {
  ClickCta = 'CLICK_CTA',
  Dismiss = 'DISMISS',
  Load = 'LOAD',
}

export enum NotificationType {
  Campaign = 'CAMPAIGN',
  Notification = 'NOTIFICATION',
}

export enum CallToActionNames {
  Unknown,
  AddNewUser = 'ADDNEWUSER',
  DismissNewOwnerRequest = 'DISMISSNEWOWNERREQUEST',
}
